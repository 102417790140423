import React from "react"
import IframeResizer from "iframe-resizer-react"
import { Box, Flex, Heading, useTheme } from "@chakra-ui/core"

import Layout from "../components/layout"

export default () => {
  const theme = useTheme()

  return (
    <Layout>
      <Box maxW={theme.breakpoints.lg} mx="auto" textAlign="center" mt="100px">
        <Box minH="600px" width="100%">
          <Heading color="pink.500"> Ajanvaraus</Heading>
          <IframeResizer
            checkOrigin="false"
            frameBorder="0"
            src="https://timma.fi/reservation/onnistua"
            title="Ajanvaraus"
            width="100%"
          />
        </Box>
      </Box>
    </Layout>
  )
}
